export const albums = [
  {
    src: require("./2024/taborova_fotka_2024.jpg"),
    year: "2024",
    title: "Na Mars!",
    date: "28. 7. - 11. 8. 2024",
  },
  {
    src: require("./2023/taborova_fotka_2023.jpg"),
    year: "2023",
    title: "O Norikovi",
    date: "30. 7. - 13. 8. 2023",
  },
  {
    src: require("./2022/taborova_fotka_2022.jpg"),
    year: "2022",
    title: "Od Atlantiku k Pacifiku",
    date: "31. 7. - 14. 8. 2022",
  },
  {
    src: require("./2021/taborova_fotka_20211.jpg"),
    year: "2021",
    title: "Výprava do Himalájí",
    date: "1. 8. - 15. 8. 2021",
  },
  {
    src: require("./2020/taborova_fotka_2020.jpg"),
    year: "2020",
    title: "Mořeplavci",
    date: "2. 8. - 16. 8. 2020",
  },
  {
    src: require("./2019/taborova_fotka_2019.jpg"),
    year: "2019",
    title: "Svět pod lupou",
    date: "28. 7. - 11. 8. 2019",
  },
];
