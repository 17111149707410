import React from "react";
import "./navbar.scss";
import { Sms, CallCalling, Facebook } from "iconsax-react";
import { NavLink } from "react-router-dom";
import MobileMenu from "./mobile-menu/MobileMenu";

export const Navbar = () => {
    return (
        <div className="navbar">
            <div className="navbar__logo-container">
                <img
                    className="navbar__logo"
                    src={require("../images/Logo_v2.png")}
                    alt="Děti u táborového ohně"
                />
                <NavLink
                    activeclassname="active"
                    className="navbar__link navbar__link--logo"
                    to="/" //havrani-zatoka-web
                    end
                />
            </div>
            <MobileMenu />
            <div className="navbar__pages">
                <NavLink
                    activeclassname="active"
                    end
                    className="navbar__link navbar__link--pages"
                    to="/"
                >
                    Domů
                </NavLink>
                <NavLink
                    activeclassname="active"
                    className="navbar__link navbar__link--pages"
                    to="/pro-rodice"
                >
                    Pro rodiče
                </NavLink>
                <NavLink
                    activeclassname="active"
                    className="navbar__link navbar__link--pages"
                    to="/tabor-pro-predskolaky"
                >
                    Tábor pro předškoláky
                </NavLink>
                <NavLink
                    activeclassname="active"
                    className="navbar__link navbar__link--pages"
                    to="/galerie"
                >
                    Galerie
                </NavLink>
                <NavLink
                    activeclassname="active"
                    className="navbar__link navbar__link--pages"
                    to="/prihlaska"
                >
                    Přihláška
                </NavLink>
            </div>
            <div className="navbar__contact-info">
                <h4 className="navbar__info">
                    <a
                        href="mailto:havranizatoka@gmail.com"
                        alt="Odkaz na Email"
                        target="_blank"
                        rel="noopener"
                    >
                        <Sms
                            //size="24"
                            className="navbar__icon navbar__icon--envelope"
                        />
                        havranizatoka@gmail.com
                    </a>
                </h4>
                <h4 className="navbar__info">
                    <a
                        href="tel:736507501"
                        alt="Odkaz na telefonní číslo"
                        target="_blank"
                        rel="noopener"
                    >
                        <CallCalling
                            //size="24"
                            className="navbar__icon navbar__icon--phone"
                        />
                        736 507 501
                    </a>
                </h4>
                <h4 className="navbar__info">
                    <a
                        href="https://www.facebook.com/people/T%C3%A1bor-na-Havran%C3%AD-z%C3%A1toce/100064688320860/"
                        alt="Odkaz na Facebook"
                        target="_blank"
                        rel="noopener"
                    >
                        <Facebook
                            //size="24"
                            className="navbar__icon navbar__icon--facebook"
                        />
                    </a>
                </h4>
            </div>
        </div>
    );
};
export default Navbar;
