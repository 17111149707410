import React, { useRef, useState, useEffect } from "react";
import "./application-form.scss";
import { useForm } from "react-hook-form";

import emailjs from "@emailjs/browser";

export const ApplicationForm = () => {
  const form = useRef();
  const { register, handleSubmit } = useForm({
    mode: "all",
  });

  const [buttonTimeout, setButtonTimeout] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false); //validace
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  //const [isFormLoading, setIsFormLoading] = useState(false);

  useEffect(() => {}, [isFormSubmitted]);

  const reloadPage = () => {
    window.location.reload();
  };

  const resetFormState = () => {
    setIsFormValid(false);
    setIsFormSubmitted(false);
    setErrorMessage(false);
    setButtonTimeout(false);
    reloadPage();
  };

  const renderMessage = () => {
    // if(isFormLoading === true){
    //     return(
    //         <div className="form_loader form_loader--small">

    //         </div>
    //     )
    // }
    if (isFormSubmitted === true) {
      if (isFormValid === true) {
        return (
          <div className="form__form-submitted__container">
            <h3 className="form__form-submitted__message">
              Předběžná přihláška byla úspěšně odeslána, brzy vás budeme
              kontaktovat.
            </h3>
            <button
              className="submit-button form__form-submitted__button"
              onClick={() => resetFormState()}
            >
              Zpět na formulář
            </button>
          </div>
        );
      } else if (isFormValid === false) {
        return (
          <div className="form__form-submitted__container">
            <h3 className="form__form-submitted__message">
              Při odesílání předběžné přihlášky nastala chyba, zkuste prosím
              přihlášku odeslat znovu. <br />
              Případně odešlete údaje přímo na naší emailovou adresu:{" "}
              <a
                href="mailto:havranizatoka@gmail.com"
                alt="Odkaz na Email"
                target="_blank"
                rel="noopener"
              >
                havranizatoka@gmail.com
              </a>
            </h3>
            <button
              className="submit-button form__form-submitted__button"
              onClick={() => resetFormState()}
            >
              Zpět na formulář
            </button>
          </div>
        );
      }
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    //setIsFormLoading(true);
    setButtonTimeout(true);
    emailjs
      .sendForm(
        // "service_qv9twlc", //udaje na osobni ucet (test)
        // "template_fsiqava",
        // form.current,
        // "Vgf9Bs67OxlLiFLWG"

        "service_7tsk2ls", //spravne udaje na havranizatoka.gmail.com ucet
        "template_cvw1a8t",
        form.current,
        "9ofuWKU650zVhtJfP"
      )
      .then(
        (result) => {
          setIsFormSubmitted(true);
          setIsFormValid(true);
          //setIsFormLoading(false);
        },
        (error) => {
          setIsFormSubmitted(true);
          //setIsFormLoading(false);
        }
      );
  };
  const onError = (errors, e) => {
    e.preventDefault();
    setErrorMessage(true);
  };

  return (
    <div>
      {isFormSubmitted === true ? (
        renderMessage()
      ) : (
        <form
          ref={form}
          className="form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          {/* {renderAlert()} */}
          {errorMessage === true ? (
            <span className="form__error">
              Zkontrolujte si prosím, že jsou všechny informace vyplňeny
              správně!
            </span>
          ) : (
            <span className="form__error"></span>
          )}
          {/* <input type="" */}
          <div className="form__input-container form__input-container--medium">
            <label className="form__label" htmlFor="first-name">
              Jméno dítěte<span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input"
              id="first-name"
              {...register("jmeno", { required: true })}
            />
          </div>
          <div className="form__input-container form__input-container--medium">
            <label className="form__label" htmlFor="last-name">
              Příjmení dítěte
              <span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input"
              id="last-name"
              {...register("prijmeni", { required: true })}
            />
          </div>
          <div className="form__input-container form__input-container--semi-small">
            <label className="form__label" htmlFor="birth-date">
              Datum narození dítěte
              <span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input form__input"
              id="birth-date"
              type="date"
              {...register("datumNarozeni", { required: true })}
            />
          </div>
          <div className="form__input-container form__input-container--semi-large">
            <label className="form__label" htmlFor="psc">
              Typ přihlášky<span className="form__required-char"> *</span>
            </label>
            <select
              className="form__input form__type-select"
              id="type"
              {...register("typPrihlasky", { required: true })}
            >
              <option
                className="form__input form__select-option"
                selected
                value="Klasický tábor"
              >
                Klasický tábor
              </option>
              <option
                className="form__input form__select-option"
                value="Tábor pro předškoláky"
              >
                Tábor pro předškoláky
              </option>
            </select>
            {/* <select
                    className="form__input form__input"
                    id="psc"
                    {...register("psc", { required: true })}
                /> */}
          </div>
          <div className="form__input-container form__input-container--full">
            <label className="form__label" htmlFor="address">
              Adresa<span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input"
              id="address"
              {...register("adresa", { required: true })}
            />
          </div>
          <div className="form__input-container form__input-container--large">
            <label className="form__label" htmlFor="city">
              Město<span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input"
              id="city"
              {...register("mesto", { required: true })}
            />
          </div>
          <div className="form__input-container form__input-container--small">
            <label className="form__label" htmlFor="psc">
              PSČ<span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input form__input"
              id="psc"
              {...register("psc", { required: true })}
            />
          </div>
          <div className="form__input-container form__input-container--semi-large">
            <label className="form__label" htmlFor="email">
              E-mail zákonného zástupce
              <span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input"
              id="email"
              type="email"
              {...register("email", {
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
            />
          </div>
          <div className="form__input-container form__input-container--semi-small">
            <label className="form__label" htmlFor="phone-number">
              Telefonní číslo zák. zástupce
              <span className="form__required-char"> *</span>
            </label>
            <input
              className="form__input"
              id="phone-number"
              type="tel"
              {...register("telefoniCislo", { required: true })}
            />
          </div>

          <div className="form_input-container form__input-container--full">
            <label className="form__label" htmlFor="phone-number">
              Poznámka
            </label>
            <textarea
              className="form__question-textarea form__input" //???????????????????????????????????
              maxLength={2000}
              {...register("poznamka", { required: false })}
            />
          </div>
          {buttonTimeout === true ? (
            <button disabled className="submit-button">
              Odeslat
            </button>
          ) : (
            <button className="submit-button">Odeslat</button>
          )}
        </form>
      )}
    </div>
  );
};
export default ApplicationForm;
