import React, { useEffect } from "react";
import "./for-parents-page.scss";
import QuestionsForm from "./QuestionForm";

export const ForParentsPage = () => {
  useEffect(() => {
    document.title =
      "Informace pro rodiče | Letní dětský tábor Osada Na Havraní zátoce 2024";
  }, []);
  return (
    <div className="for-parents-page page">
      <h1 className="page__title">Informace pro rodiče</h1>
      <div className="for-parents-page__body page__body">
        <section className="for-parents-page__section">
          <ul className="for-parents-page__basic-info">
            <li>
              Osada Na Havraní zátoce je letní tábor pro děti do 14 let včetně.
              Přijmeme i děti v předškolním věku.
            </li>
            <li>
              Naše základna se nachází v údolí řeky Úhlavky mezi městy Stříbro a
              Kladruby.
            </li>
            <li>
              Tábor je 14denní a začíná každoročně na přelomu července a srpna.
            </li>
            <li>
              Jedná se o sportovně zaměřený tábor, na kterém si děti zahrají
              spoustu her v přírodě, poznají nové kamarády a zažijí jedinečnou
              atmosféru tábora.
            </li>
            <li>
              Naučí se také mnoho nových dovedností (např. rozdělání ohně,
              zdravověda, šifry, uzly...).
            </li>
            <li>
              Na začátku tábora jsou děti rozdělené do oddílů a po celou dobu
              spolu soupeří v celotáborové hře. Ta je pro děti do poslední
              chvíle překvapením a dozví se její téma formou scénky v den
              příjezdu.
            </li>
            <li>
              I ve volném čase je pro děti připraveno mnoho pohybových i
              odpočinkových aktivit. Na začátku a na konci tábora jsou pak dva
              velké osadní ohně, při kterých se děti společně baví zpěvem,
              vtipnými scénkami, které si každý oddíl připraví a zažívají
              táborovou romantiku.
            </li>
            <li>
              Letos pořádáme již{" "}
              <nobr>
                <b>{new Date().getFullYear() - 1994}. ročník</b>
              </nobr>{" "}
              a největší odměnou jsou pro nás úsměvy dětí, které se k nám každý
              rok rády vrací
              {" :-)"}.
            </li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">Cena</h2>
          <ul>
            <li>
              <b>5.200 Kč.</b>
            </li>
            <li>
              Zahrnuje: Dopravu autobusem z Berouna na tábor a zpět, stravu,
              ubytování, materiál na činnost a hry...
            </li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">
            Přeprava na tábor / z tábora
          </h2>
          <ul>
            <li>
              Sraz je každoročně v Berouně (čas a přesné místo upřesníme v
              přihlášce).
            </li>
            <li>Na tábor odjíždíne společně autobusem.</li>
            <li>
              Příjezd, či odjezd z tábora je možné si domluvit individuálně.
            </li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">Vedoucí</h2>
          <ul>
            <li>O vaše děti se bude starat parta zkušených vedoucích.</li>
            <li>
              Více než{" "}
              <nobr>
                <b>30 let</b>
              </nobr>{" "}
              zkušeností s organizací tábora.
            </li>
            <li>Každý vedoucí si táborem sám prošel.</li>
            {/* <li>
                            Na každý oddíl (10-12 dětí) připadají 2-3 vedoucí, o
                            děti je tak vždy postaráno.
                        </li> */}
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">Ubytování</h2>
          <ul>
            <li>Děti jsou ubytované po dvou ve stanech s dřevěnou podsadou.</li>
            <li>Stany jsou rozděleny do náměstíček (podle oddílů).</li>

            <li>
              V každém náměstíčku je Teepee, kde se děti mohou bavit za
              nepříznivého počasí a pokud budou chtít, mohou v nich i přespat.
            </li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">Strava</h2>
          <ul>
            <li>Vaří nám zkušený tým kuchařů v dobře vybavené kuchyni.</li>
            <li>Strava 5x denně v zastřešené jídelně.</li>
            <li>2x denně teplé jídlo(oběd, večeře), teplý čaj k snídani.</li>
            <li>Vaříme zdravě a chutně.</li>
            <li>Děti jídla většinou velmi chválí.</li>
            <li>
              Speciální požadavky na stravu jsou po domluvě možné (bezlepková
              dieta...).
            </li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">Zdravotní péče</h2>
          <ul>
            <li>O děti se stará kvalifikovaná zdravotnice.</li>
            <li>Všichni vedoucí jsou proškoleni v první pomoci.</li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">Sociální zařízení</h2>
          <ul>
            <li>Suché záchody s umývárnou.</li>
            <li>K dispozici je sušárna na mokré oblečení a obuv.</li>
            <li>Sprchy s teplou vodou.</li>
            {/* <li>Umývárny.</li> */}
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">
            Vybavení a aktivity pro děti
          </h2>
          <ul>
            <li>
              <b>Oddílové aktivity: </b>Střelba z luku, foukačky a laserové
              vzduchovky, malé lanové centrum s lanovkou, trampolína, oddílové
              hry, soupeření v celotáborové hře...
            </li>
            <li>
              <b>Vybavení pro volný čas: </b>
              stoly na stolní tenis, vybavení na míčové hry, bariball, diskgolf,
              rakety na badminton a speed badminton, spousta deskových a
              karetních her, materiály na kreativní tvoření a mnoho dalších...
            </li>
            <li>Pro děti máme připravenou spoustu odměn {" :-)"}.</li>
          </ul>
        </section>
        <section className="for-parents-page__section">
          <h2 className="for-parents-page__section-title">
            Historie celotáborových her
          </h2>
          <ul>
            <li>2024 - Na Mars!</li>
            <li>2023 - O Norikovi</li>
            <li>2022 - Od Atlantiku k Pacifiku</li>
            <li>2021 - Vypráva do Himalájí</li>
            <li>2020 - Mořeplavci</li>
            <li>2019 - Svět pod lupou</li>
            <li>2018 - Atlantida</li>
            <li>2017 - Bájné Okavango</li>
            <li>2016 - Hvězdná brána</li>
            <li>2015 - V zemi krále Artuše</li>
            <li>2014 - Pevnost Boyard</li>
            <li>2013 - Cesta do hlubin hory</li>
            <li>2012 - Na cestě kolem světa</li>
            <li>2011 - V čarodějné zemi</li>
            <li>2010 - Tenkrát na západě</li>
            {/* <li>2009 - Řecko</li> */}
            <li>...</li>
          </ul>
        </section>
        {/* <section className="for-parents-page__section">
                    <h2 className="for-parents-page__section-title">
                        Denní režim
                    </h2>
                    <ul>
                        <li>8:00 Budíček</li>
                        <li>8:05 Ranní rozcvička</li>
                        <li>8:15 Ranní hygiena, úklid stanů</li>
                        <li>8:30 Snídaně</li>
                        <li>9:00 Dopolední program</li>
                        <li>12:00 Oběd</li>
                        <li>12:30 Polední klid</li>
                        <li>13:30 Volná zábava</li>
                        <li>14:00 Svačina</li>
                        <li>14:15 Odpolední program</li>
                        <li>18:15 Večeře</li>
                        <li>19:00 Večerní nástup</li>
                        <li>19:15 Večerní program</li>
                        <li>21:00 Druhá večeře (nepovinná)</li>
                        <li>21:10 Večerní hygiena</li>
                        <li>21:30 Všichni ve stanech</li>
                        <li>22:00 Večerka</li>
                    </ul>
                </section> */}
      </div>
      <QuestionsForm />
    </div>
  );
};
export default ForParentsPage;
